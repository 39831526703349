import React, { useReducer, useContext, createContext, useState, useEffect } from "react"
import { useReservationContext } from './Provider'
import { useForm } from "react-hook-form";
import Button from '../Button'
import { formatDate, useDebounce, formatCurrency, getVehicleImageUrl } from '../../src/utils'
import CurrencyField from '../CurrencyField'
import ProductLineItemForm, { TargetTypes } from './ProductLineItemForm'
import PaymentForm,{PayeableType} from './PaymentForm'
import { motion, AnimatePresence } from "framer-motion";
import client from "../../src/client"




export default function TransactionForm({ onSave, target = TargetTypes.DraftLineItem }) {

    const { state, dispatch } = useReservationContext()
    const { register, handleSubmit, watch, errors, reset, setValue } = useForm();
    const [showPayment,setShowPayment] = useState(false)
    const [busy,setBusy] = useState(false)
    const [subTotal,setSubTotal] = useState(0)
    let lineItem = state.lineItems[state.lineItemIndex]

    const [transaction,setTransaction] = useState<any>({
        lineItem: state.draftLineItem
    })

    useEffect(() => {
        if(state.transaction) {
            console.log("TransactionForm useEffect",state.transaction)
            setTransaction(state.transaction)
            setShowPayment(true)
            let st = state.transaction.lineItems.reduce((acc,lineItem)=>{
                acc += (lineItem.quantity * lineItem.unitPrice)
                return acc
            },0)
            setSubTotal(st)
        } else {
            console.log("TransactionForm useEffect",state.draftLineItem)
            let st = state.draftLineItem.lineItems.reduce((acc,lineItem)=>{
                acc += (lineItem.quantity * lineItem.unitPrice)
                return acc
            },0)
            setSubTotal(st)
        }
    }, [state.transaction]);

    useEffect(() => {

    }, []);


    async function togglePay() {

        if(transaction && transaction.id) {
            setBusy(true)
            let r = await client.put(`/api/v1/transactions/${transaction.id}`,{
                linkedLineItemId: lineItem.id,
                checkinId: state.checkin?.id,
                ...state.draftLineItem
            })
            setTransaction(r.data)
            setShowPayment(!showPayment)
        } else {
            setBusy(true)
            let r = await client.post(`/api/v1/transactions`,{
                linkedLineItemId: lineItem.id,
                checkinId: state.checkin?.id,
                ...state.draftLineItem
            })
            setTransaction(r.data)
            setShowPayment(!showPayment)
        }
        
        setBusy(false)
        
    }

    return (
        
        <div>

            <div className="mb-5 flex items-center">
                <div className="w-32">
                    <img src={getVehicleImageUrl(lineItem.trackDateVehicle)} />
                </div>
                <div>
                    <div className="font-bold">{formatDate(lineItem.trackDate.date)}</div>
                    <div>{lineItem.trackDate.track.name}</div>
                    <div>Organizer: {lineItem.trackDate.trackDateGroup.eventOrganizer}</div>
                    <div>{lineItem.trackDateVehicle.vehicle.name}</div>
                </div>
            </div>

            <div className="mb-2 overflow-hidden relative" style={{height:500}}>

                <AnimatePresence initial={false}>
                    {!showPayment && 
                        <motion.div
                            key="cart"
                            style={{
                                width: '100%',
                                height: '100%',
                                position: "absolute",                                
                            }}
                            initial={{
                                left: -600
                            }}
                            exit={{
                                left: -800
                            }}
                            animate={{
                                left: 0
                            }}>                                    
                            <div>
                                <ProductLineItemForm editable={true} target={target} />
                            </div>
                        </motion.div>
                    }
                        {showPayment &&
                            <motion.div
                                key="pay"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    position: "absolute",                                    
                                }}
                                initial={{
                                    right: -600,
                                }}
                                exit={{
                                    right: -800
                                }}
                                animate={{
                                    right: 0,
                                }}>                
                                <div className="">
                                    
                                <PaymentForm 
                                    payeableType={PayeableType.Transaction}
                                    payeableId={transaction.id}
                                    initialAmount={subTotal} 
                                    onSuccess={async ()=>{
                                        onSave()
                                    }}/>                                

                                </div>
                            </motion.div>
                        }
                    </AnimatePresence>

            </div>


            {(subTotal > 0 && !showPayment) && <div>
                <Button busy={busy} onClick={togglePay} block>Pay  {formatCurrency(subTotal)}</Button>
            </div>}
        </div>
       
    )
}