import produce from "immer"
import React, { createContext, useContext, useEffect, useReducer } from "react"
import client from "../../src/client"
import { getUnitPrice } from '../../src/utils'
import {
    Checkin,
    Customer,
    Discount,
    Driver,
    Payment,
    ProductLineItem,
    Reservation,
    TrackDate,
    TrackDateProduct,
    TrackDateVehicle,
    Transaction,
    VehicleLineItem
} from "../types"
import { ActionType, ReservationFormActions } from '../types/actions'
import { mapDiscount, mapDriver, mapPayment, mapTrackDate, mapTrackDateVehicle, mapTransaction } from "./mappers"
import { TargetTypes } from "./ProductLineItemForm"

type State = {
    id?: number;
    editable: boolean;
    lineItems: VehicleLineItem[];
    customer?: Customer;
    drivers: Driver[],
    showDriverModal: boolean;
    showPaymentModal: boolean;
    showRefundModal: boolean;
    showCancelModal: boolean;
    showVoidModal: boolean;
    showTransactionModel: boolean;
    initialReservation?: Reservation;
    draftLineItem: VehicleLineItem;
    transaction: Transaction
    lineItemIndex?:number;
    orderTotal:number;
    orderTotalBeforeDiscounts:number;
    discountTotal:number;
    paymentsTotal:number;
    balanceDue:number;
    payments: Payment[];
    payment?: Payment
    creditBalance?: number,
    discount?:Discount,
    discountError?:string,
    locked: boolean;
    vipSyncInProgress: boolean;
    checkin: Checkin
    driver: Driver;
}


function buildLineItem(id:number,trackDate:TrackDate,trackDateVehicle:TrackDateVehicle,driver:Driver,initialLineItem:VehicleLineItem):VehicleLineItem {

    let requiredLineItems = []
    console.log("buildLineItem")
    console.log("initialLineItem=", initialLineItem)
    console.log("trackDate=", trackDate)
    console.log("trackDateVehicle=", trackDateVehicle)

    if(initialLineItem) {
        for(let initialAddOnLineItem of initialLineItem.line_items) {
            requiredLineItems.push({
                id: initialAddOnLineItem.id,
                trackDateProduct: trackDate.trackDateProducts.find((x)=>x.product.id == initialAddOnLineItem.product_id) ,
                quantity: parseInt( initialAddOnLineItem.quantity ),
                unitPrice: parseFloat(initialAddOnLineItem.unit_price),
                subTotal: parseFloat(initialAddOnLineItem.sub_total)
            })
        }
    } else {

        for(let trackDateProduct of trackDate.trackDateProducts) {
            if(trackDateProduct.product.required || trackDateProduct.product.suggested) {
    
                let unitPrice = trackDateProduct.price ? trackDateProduct.price : 0
    
                if(trackDateProduct.product.pricedByVehicle) {
                    let vehicleProduct = trackDateVehicle.vehicle.vehicleProducts.find(x=>x.product.id == trackDateProduct.product.id)
                    if(vehicleProduct) {
                        unitPrice = vehicleProduct.price
                    }
                }

                if(trackDateProduct.product.pricedByTrackDate) {
                    unitPrice = trackDate.trackDateGroup.registrationFee
                }

                console.log(`required product=${trackDateProduct.product.title} unitPrice=${unitPrice}`)

                requiredLineItems.push({
                    trackDateProduct: trackDateProduct,
                    quantity: 1,
                    unitPrice: unitPrice,
                    subTotal: unitPrice
                })
            }
        }

    }

    let lineItem:VehicleLineItem = {
        id: id,
        trackDateVehicle: trackDateVehicle,
        quantity: 1,
        unitPrice: trackDateVehicle.price,
        trackDate: trackDate,
        lineItems: requiredLineItems,
        driver: driver,
        originalUnitPrice: null,
        transactions: (initialLineItem != null) ? initialLineItem.transactions : []
    }

    if(initialLineItem) {
        lineItem.unitPrice = parseFloat( initialLineItem.unit_price )
    }


    return lineItem
}


function getDiscount(state:State) {

    let discount = state.discount
    
    if(!discount) {
        return 0
    }

    let ids = []
    let discountAmount = 0
    let totalToDiscount = 0
    let trackDates = discount.trackDateGroup.trackDates
    let lineItems = state.lineItems

    if(trackDates && trackDates.length > 0) {
        console.log("filter by track date as well=",trackDates)
        lineItems = lineItems.filter((lineItem)=>{
            if( trackDates.find(x=>x.id == lineItem.trackDate.id) ) {
                return true
            }
            return false
        })
    }



    if(discount.priceRule == "all_line_items") {
        totalToDiscount = state.orderTotal
    }

    if(discount.priceRule == "all_vehicle_line_items") {
        totalToDiscount = lineItems.reduce((acc,lineItem)=>{
            acc += lineItem.unitPrice
            return acc
        },0)
    }

    if(discount.priceRule == "specific_vehicle_line_items") {
        totalToDiscount = lineItems.reduce((acc,lineItem)=>{
            if(lineItem.trackDateVehicle.vehicle.id == discount.vehicle.id) {
                acc += lineItem.unitPrice
            }
            return acc
        },0)
    }

    if(discount.priceRule == "all_product_line_items") {
        totalToDiscount = lineItems.reduce((acc,lineItem)=>{

            acc = lineItem.lineItems.reduce((acc,lineItem)=>{
                acc += lineItem.subTotal
                return acc
            },0)

            return acc
        },0)
    }

    if(discount.priceRule == "specific_product_line_items") {
        totalToDiscount = lineItems.reduce((acc,lineItem)=>{

            acc += lineItem.lineItems.reduce((acc2,lineItem)=>{

                if(lineItem.trackDateProduct.product.id == discount.product.id) {
                    acc2 += lineItem.subTotal
                }

                return acc2
            },0)

            return acc
        },0)
    }

    console.log("totalToDiscount=", totalToDiscount)

    if(discount.fixed) {
        if(discount.discountAmount > totalToDiscount) {
            discountAmount = totalToDiscount
        } else {
            discountAmount = discount.discountAmount
        }
    }

    if(discount.percentage) {
        discountAmount = totalToDiscount * (discount.percentageAmount/100)
    }

    console.log("discountAmount=", discountAmount)

    return discountAmount
}


export const Context = createContext<{
    state: State,
    dispatch: React.Dispatch<any>,
    save: Function,
    vipSync: Function,
    vipMarkAsPaid: Function,
    makePayment: Function,
    reloadReservation: Function,
    reloadCheckin: Function,
    cancelTransaction: Function,
    editTransaction: Function,
    applyDiscount: Function,
    createAuthorizationRequest: Function,
    clearAuthorizationRequest: Function,
    updateDriver: Function
}>(null)

const reducer = (state:State, action:ReservationFormActions) => {
    switch (action.type) {

        case ActionType.VipSyncInProgress: {
            return {
                ...state,
                vipSyncInProgress: action.value
            }
        }

        case ActionType.SetCheckin: {

            let lineItemIndex = state.lineItems.findIndex(x=>x.id == action.data.lineItemId)
            let lineItem = state.lineItems[lineItemIndex]

            console.log("lineItem=", lineItem)
            console.log("lineItemIndex=", lineItemIndex)

            let fuelProduct = lineItem.trackDate.trackDateProducts.find(x=>x.product.id == 28)
            let deductibleProduct = lineItem.trackDate.trackDateProducts.find(x=>x.product.id == 27)
            let productLineItems = []

            let fuelLineItem:ProductLineItem = {
                id:null,
                trackDateProduct: fuelProduct,
                trackDate: lineItem.trackDate,
                quantity: action.data.gallonsUsed,
                unitPrice: action.data.fuelPrice,
                subTotal: action.data.totalFuelPrice,
                originalPrice: null,
                originalQuantity: null
            }            
            productLineItems.push(fuelLineItem)
            console.log("fuelLineItem=", fuelLineItem)

            if( action.data.deductibleApplied > 0 ) {
                let deductibleLineItem:ProductLineItem = {
                    id:null,
                    trackDateProduct: deductibleProduct,
                    trackDate: lineItem.trackDate,
                    quantity: 1,
                    unitPrice: action.data.deductibleApplied,
                    subTotal: action.data.deductibleApplied,
                    originalPrice: null,
                    originalQuantity: null
                }
                productLineItems.push(deductibleLineItem)
                console.log("deductibleLineItem=", deductibleLineItem)
            }

            console.log("productLineItems=", productLineItems)

            let draftLineItem:VehicleLineItem = {
                ...lineItem,
                lineItems: productLineItems
            }
            return {
                ...state,
                checkin: action.data,
                lineItemIndex: lineItemIndex,
                draftLineItem: draftLineItem
            }
        }

        case ActionType.EditTransaction: {
            console.log("action=", action)
            let show = !state.showTransactionModel
            let draftLineItem:VehicleLineItem = null
            let lineItem = state.lineItems[action.lineItemIndex]

            if(show == true) {
                draftLineItem = {
                    ...lineItem,
                    id: null,
                    lineItems: []
                }
            }

            return {
                ...state,
                draftLineItem: draftLineItem,
                transaction: action.transaction,
                lineItemIndex: action.lineItemIndex,
                showTransactionModel: show
            }
        }

        case ActionType.ToggleNewTransaction: {

            let show = !state.showTransactionModel
            let draftLineItem:VehicleLineItem = null
            let lineItem = state.lineItems[action.lineItemIndex]

            if(show == true) {
                draftLineItem = {
                    ...lineItem,
                    id: null,
                    lineItems: []
                }
            }

            return {
                ...state,
                draftLineItem: draftLineItem,
                lineItemIndex: action.lineItemIndex,
                showTransactionModel: show
            }
        }

        case ActionType.SetInvalidDiscount: {
            return {
                ...state,
                discountError: action.data.message
            }
        }

        case ActionType.SetDiscount: {
            return {
                ...state,
                discount: mapDiscount(action.data)
            }
        }

        case ActionType.ToggleEditMode: {
            return {
                ...state,
                editable: !state.editable
            }
        }

        case ActionType.UpdateCreditBalance: {
            return {
                ...state,
                creditBalance: action.amount
            }
        }

        case ActionType.UpdateOrderTotals: {

            let orderTotal = state?.lineItems?.reduce((acc,lineItem)=>{
                let lineItemSubTotal = lineItem.lineItems?.reduce((acc,lineItem)=>{
                    return acc + (lineItem.quantity * lineItem.unitPrice)
                },0)
    
                return acc + lineItemSubTotal + (lineItem.quantity * lineItem.unitPrice)
            },0)

            let orderTotalBeforeDiscounts = orderTotal
            let discountTotal = getDiscount(state)

            orderTotal = orderTotal - discountTotal

            let paymentTotal = state.payments.reduce((acc,payment)=>{

                let amount = payment.amount

                if(payment.refundedAt) {
                    amount = payment.amount
                }

                return acc + amount
            },0)

            console.log("orderTotal=", orderTotal)
            console.log("paymentTotal=", paymentTotal)

            let balanceDue = orderTotal - paymentTotal

            let editable = state.editable
            if(balanceDue == 0) {
                //editable = false
            }

            return {
                ...state,
                orderTotalBeforeDiscounts: orderTotalBeforeDiscounts,
                discountTotal: discountTotal,
                paymentsTotal: paymentTotal,
                orderTotal: orderTotal,
                balanceDue: balanceDue,
                editable: editable
            }
        }

        case ActionType.BeginRefund: {

            console.log("BeginRefund=", action)

            return {
                ...state,
                showRefundModal: true,
                payment: action.payment
            }
        }

        case ActionType.OpenRefundModal: {
            return {
                ...state,
                showRefundModal: true
            }
        }

        case ActionType.CloseRefundModal: {
            return {
                ...state,
                showRefundModal: false
            }
        }

        case ActionType.OpenPaymentModal: {
            return {
                ...state,
                showPaymentModal: true
            }
        }

        case ActionType.ClosePaymentModal: {
            return {
                ...state,
                showPaymentModal: false
            }
        }

        case ActionType.SetReservation: {


            let lineItems = action.data.lineItems.map((data)=>{
                return buildLineItem(data.id,data.trackDate,data.trackDateVehicle,data.driver,data)
            })

            let locked = false

            if(action.data?.cancelledAt) {
                //locked = true
            }

            if(action.data?.voidedAt) {
                //locked = true
            }

            if(action.data?.state == "payment_received") {
                locked = true
            }

            console.log("SetReservation locked=", locked)

            return {
                ...state,
                id: action.data.id,
                customer: action.data.customer,
                lineItems: lineItems,
                payments: action.payments,
                discount: action.data.discount,
                locked: locked,
                initialReservation: {
                    refundedAt: action.data?.refundedAt,
                    createdAt: action.data?.createdAt,
                    cancelledAt: action.data?.cancelledAt,
                    paymentLinkSentAt: action.data?.paymentLinkSentAt,
                    state: action.data?.state,
                    voided: action.data.voidedAt,
                    cancelReason: action.data?.cancelReason,
                    quickbooksId: action.data?.quickbooksId,
                    quickbooksErrorMessage: action.data?.quickbooksErrorMessage,
                    quickbooksFailedAt: action.data?.quickbooksFailedAt,
                    quickbooksSyncAt: action.data?.quickbooksSyncAt,
                    lineItems: lineItems,
                    vipSyncedAt: action.data?.vipSyncedAt,
                    vipPaymentReceivedAt: action.data?.vipPaymentReceivedAt
                }
            }
        }

        case ActionType.AddProductToLineItem: {

            console.log("AddProductToLineItem=", action)
            console.log("lineItems=", state.lineItems)

            let buildLineItem = (lineItem:VehicleLineItem,trackDateProduct:TrackDateProduct) => {


                let unitPrice = getUnitPrice(trackDateProduct,lineItem)                    
                let product = trackDateProduct.product
                let trackDate = lineItem.trackDate
                //let trackDateProduct = trackDate.trackDateProducts.find(x=>x.product.id == product.id)
                
                let draftLineItem:ProductLineItem = {
                    id:null,
                    trackDateProduct: trackDateProduct,
                    trackDate: trackDate,
                    quantity: 1,
                    unitPrice: unitPrice,
                    subTotal: unitPrice * 1,
                    originalPrice: null,
                    originalQuantity: null
                }

                return draftLineItem
            }

            if(action.target == TargetTypes.Reservation) {
                return {
                    ...state,
                    lineItems: produce(state.lineItems,draft=>{
                        let lineItem = state.lineItems[action.lineItemIndex]    
                        console.log("lineItem=", lineItem)
                        draft[action.lineItemIndex].lineItems.push(buildLineItem(lineItem,action.trackDateProduct))
                    })
                }
            }

            if(action.target == TargetTypes.DraftLineItem || action.target == TargetTypes.Checkin) {
                return {
                    ...state,
                    draftLineItem: {
                        ...state.draftLineItem,
                        lineItems: produce(state.draftLineItem.lineItems,draft=>{
                            draft.push(buildLineItem(state.draftLineItem,action.trackDateProduct))
                        })
                    }
                }
            }



        }

        case ActionType.UpdateAddonQuantity: {

            if(action.target == TargetTypes.Reservation) {
                return {
                    ...state,
                    lineItems: produce(state.lineItems,draft=>{

                        let lineItem = draft[action.lineItemIndex]
                        let productId = action.trackDateProduct.product.id
                        let addOnLineItemIndex = lineItem.lineItems.findIndex(x=>x.trackDateProduct.product.id == productId)
                        let quantity = action.quantity
    
                        if(quantity > 0) {
                            lineItem.lineItems[addOnLineItemIndex].quantity = quantity
                            lineItem.lineItems[addOnLineItemIndex].subTotal = lineItem.lineItems[addOnLineItemIndex].unitPrice * quantity
                        } else {
                            lineItem.lineItems.splice(addOnLineItemIndex,1)
                        }
        
                    })
                }
            }

            if(action.target == TargetTypes.DraftLineItem || action.target == TargetTypes.Checkin) {
                return {
                    ...state,
                    draftLineItem: {
                        ...state.draftLineItem,
                        lineItems: produce(state.draftLineItem.lineItems,draft=>{

                            let lineItem = state.draftLineItem
                            let productId = action.trackDateProduct.product.id
                            let addOnLineItemIndex = lineItem.lineItems.findIndex(x=>x.trackDateProduct.product.id == productId)
                            let quantity = action.quantity
        
                            if(quantity > 0) {
                                draft[addOnLineItemIndex].quantity = quantity
                                draft[addOnLineItemIndex].subTotal = lineItem.lineItems[addOnLineItemIndex].unitPrice * quantity
                            } else {
                                draft.splice(addOnLineItemIndex,1)
                            }

                        })
                    }
                }
            }
        }

        case ActionType.UpdateAddonPrice: {


            if(action.target == TargetTypes.Reservation) {
                return {
                    ...state,
                    lineItems: produce(state.lineItems,draft=>{
    
                        let lineItem = draft[action.lineItemIndex]
                        let productId = action.trackDateProduct.product.id
                        let addOnLineItemIndex = lineItem.lineItems.findIndex(x=>x.trackDateProduct.product.id == productId)
    
                        lineItem.lineItems[addOnLineItemIndex].unitPrice = action.price
                        lineItem.lineItems[addOnLineItemIndex].subTotal = lineItem.lineItems[addOnLineItemIndex].quantity * action.price      
        
                    })
                }
            }

            if(action.target == TargetTypes.DraftLineItem || action.target == TargetTypes.Checkin) {
                return {
                    ...state,
                    draftLineItem: {
                        ...state.draftLineItem,
                        lineItems: produce(state.draftLineItem.lineItems,draft=>{

                            let lineItem = state.draftLineItem
                            let productId = action.trackDateProduct.product.id
                            let addOnLineItemIndex = lineItem.lineItems.findIndex(x=>x.trackDateProduct.product.id == productId)
        
                            draft[addOnLineItemIndex].unitPrice = action.price
                            draft[addOnLineItemIndex].subTotal = lineItem.lineItems[addOnLineItemIndex].quantity * action.price      
            
                        })
                    }
                }
            }

        }

        case ActionType.UpdateLineItemPrice: {

            return {
                ...state,
                lineItems: produce(state.lineItems,draft=>{

                    
                    
                    let lineItemIndex = action.lineItemIndex
                    if(action.target == TargetTypes.Checkin) {
                        lineItemIndex = state.lineItemIndex
                    }

                    let draftLineItem = draft[lineItemIndex]

                    let initialLineItem = state.initialReservation.lineItems.find(x=>x.id == draftLineItem.id)
                    if(initialLineItem && (initialLineItem.unitPrice > action.price || initialLineItem.unitPrice < action.price)) {
                        draftLineItem.originalUnitPrice = initialLineItem.unitPrice
                    } else {
                        draftLineItem.originalUnitPrice = null
                    }

                    draftLineItem.unitPrice = action.price

                })
            }
        }

        case ActionType.AddLineItem: {



            let defaultDriver = null
            if(state.drivers.length > 0) {
                defaultDriver = state.drivers[0]
            }


            let newLineItem:VehicleLineItem = buildLineItem(null,action.trackDate,action.trackDateVehicle,defaultDriver)

            return {
                ...state,
                lineItems: [
                    ...state.lineItems,
                    newLineItem
                ]
            }
        }

        case ActionType.RemoveLineItem: {
            return {
                ...state,
                lineItems: produce(state.lineItems,draft=>{
                    draft.splice(action.lineItemIndex,1)
                })
            }
        }
        
        case ActionType.AddDriver: {
            console.log("AddDriver=", action)
            return {
                ...state,
                driver: {},
                showDriverModal: true,
                lineItemIndex: action.lineItemIndex
            }
        }

        case ActionType.SetCustomer: {

            let drivers = []
            if(action.customer.drivers) {
                drivers = action.customer.drivers.map(mapDriver)
            }

            return {
                ...state,
                showCustomerForm: false,
                customer: {
                    ...action.customer,
                    drivers: drivers
                }
            }
        }

        case ActionType.ClearCustomer: {
            return {
                ...state,
                customer: null
            }
        }

        case ActionType.ClearDiscount: {
            return {
                ...state,
                discount: null
            }
        }

        case ActionType.CloseDriverModal: {
            return {
                ...state,
                lineItemIndex: null,
                showDriverModal: false
            }
        }

        case ActionType.SelectDriver: {

            return {
                ...state,
                lineItems: produce(state.lineItems,draft=>{
                    
                    let driver = state.customer.drivers.find(x=>x.id == action.id)
                    console.log("select driver=", driver)
                    draft[action.lineItemIndex].driver = driver

                })                
            }
        }

        case ActionType.EditDriver: {
            return {
                ...state,
                driver: action.driver,
                showDriverModal: true,
                lineItemIndex: action.lineItemIndex                
            }
        }

        case ActionType.SetDriver: {

            console.log("SetDriver",action)


            return {
                ...state,
                customer: {
                    ...state.customer,
                    drivers: produce(state.customer?.drivers,draft=>{
                        if(!state.customer) {
                            return
                        }
                        let driverIndex = state.customer.drivers.findIndex(x=>x.id == action.driver.id)
                        if(driverIndex == -1) {
                            draft.push(action.driver)
                        } else {
                            draft[driverIndex] = action.driver
                        }
                    })
                },
                drivers: produce(state.drivers,draft=>{
                    let driverIndex = state.drivers.findIndex(x=>x.id == action.driver.id)
                    if(driverIndex == -1) {
                        draft.push(action.driver)
                    } else {
                        draft[driverIndex] = action.driver
                    }
                }),
                lineItems: produce(state.lineItems,draft=>{
                    draft[state.lineItemIndex].driver = action.driver
                })
            }
        }

        case ActionType.SetCancelReservationModal: {
            return {
                ...state,
                showCancelModal: action.value
            }
        }

        case ActionType.SetVoidReservationModal: {
            return {
                ...state,
                showVoidModal: action.value
            }
        }
    }
}


const initialState: State = {
    id: null,
    lineItems: [],
    editable: false,
    drivers: [],
    payments: [],
    paymentsTotal:0,
    orderTotal: 0,
    orderTotalBeforeDiscounts: 0,
    balanceDue: 0,
    discountTotal: 0,
    customer: null,
    showDriverModal: false,
    showCancelModal: false,
    showVoidModal: false,
    showTransactionModel: false,
    creditBalance: null,
    showPaymentModal: false,
    showRefundModal: false,
    initialReservation: null,
    discount: null,
    locked: false,
    draftLineItem: null,
    checkin: null,
    driver: null
}

export function Provider({ children,id,checkinId,editable }) {

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(()=>{
        dispatch({type:ActionType.UpdateOrderTotals})
    },[state.lineItems,state.payments,state.discount])


    async function updateDriver( driverId, lineItemIndex ) {
        try {

            console.log("all drivers=", state.customer.drivers)
            console.log("updateDriver.lineItemIndex=", lineItemIndex)
            console.log("updateDriver.driverId=", driverId)

            //let driver = state.customer.drivers.find(d=>d.id == parseInt(driverId))
            let lineItem = state.lineItems[lineItemIndex]
            console.log("updateDriver.driver=", driverId)
            console.log("updateDriver.lineItem=", lineItem)

            try {
                let r = await client.post(`/api/v1/reservations/${id}/line_items/${lineItem.id}/driver/${driverId}`)
            } catch(e) {
                console.log("failed to update driver, probably a new line item")
            }


            dispatch({type:ActionType.SelectDriver,id:driverId,lineItemIndex:lineItemIndex})

        } catch(e) {
            console.error(e)
        }
    }

    async function loadCustomerBalance() {
        try {
            let r = await client.get(`/api/v1/reservations/${id}/balance/${state.customer.id}`)
            dispatch({type:ActionType.UpdateCreditBalance,amount: parseFloat(r.data.amount)})
        } catch(e) {
            console.error(e)
        }
    }

    async function loadReservation(id) {
        let r = await client.get(`/api/v1/reservations/${id}`)

        let data:Reservation = {
            id: r.data.id,
            state: r.data.state,
            balanceDue: parseFloat(r.data.balance_due),
            totalPrice: parseFloat(r.data.total_price),
            discountsTotal: parseFloat(r.data.discounts_total),
            voidedAt: r.data.voided_at,
            cancelledAt: r.data.cancelled_at,
            createdAt: r.data.created_at,
            cancelReason: r.data.cancel_reason,
            paymentLinkSentAt: r.data.payment_link_sent_at,
            quickbooksId: r.data.get_quickbooks_id,
            quickbooksSyncAt: r.data.quickbooks_succeeded_at,            
            customer: null,
            refundedAt: r.data.refunded_at,
            quickbooksErrorMessage: r.data.quickbooks_error_message,
            quickbooksFailedAt: r.data.quickbooks_failed_at,
            discount: mapDiscount(r.data.discount),
            vipPaymentReceivedAt: r.data.vip_payment_received_at,
            vipSyncedAt: r.data.vip_synced_at,
            lineItems: r.data.line_items.map((lineItemData)=>{
                return {
                    ...lineItemData,
                    transactions: lineItemData.transactions.map(mapTransaction),
                    driver: mapDriver(lineItemData.driver),
                    trackDateVehicle: mapTrackDateVehicle(lineItemData.track_date_vehicle),
                    trackDate: mapTrackDate(lineItemData.track_date)
                }
            })
        }

        if(r.data.customer) {
            data.customer = {
                ...r.data.customer,
                drivers: r.data.customer ? r.data.customer.drivers.map(mapDriver) : []
            }
        }

        console.log("data=", data)

        dispatch({type: ActionType.SetReservation, data: data,payments: r.data.payments.map(mapPayment)})
    }

    async function reloadReservation() {
        await loadReservation(state.id)
    }

    async function reloadCheckin() {
        console.log("reload checkin!", state.checkin)
        if(state.checkin) {
            await loadCheckin(state.checkin.id)
        }
        if(state.id) {
            await reloadReservation()
        }        
    }

    async function cancelTransaction( transaction ) {
        await client.delete(`/api/v1/transactions/${transaction.id}`)
        await reloadCheckin()
    }

    function editTransaction( transaction, lineItemIndex ) {
        dispatch({type:ActionType.EditTransaction,transaction:transaction,lineItemIndex:lineItemIndex})
    }

    async function loadCheckin(id:number) {

        let r = await client.get(`/api/v1/checkins/${id}`)
        await loadReservation(r.data.line_item.reservation_id)
        
        let data:Checkin = {
            id: r.data.id,
            fuelPrice: r.data.fuel_price,
            totalFuelPrice: r.data.total_fuel_price,
            totalPrice: r.data.total_price,
            balanceDue: r.data.balance_due,
            mileageIn: r.data.mileage_in,
            mileageOut: r.data.mileage_out,
            deductible: r.data.deductible,
            deductibleApplied: r.data.deductible_applied,
            gallonsUsed: r.data.gallons_used,
            lineItemId: r.data.line_item_id,
            transactions: r.data.transactions.map(mapTransaction)
        }

        
        dispatch({type:ActionType.SetCheckin, data: data})

    }   

    async function makePayment() {
        await save()
        dispatch({type:ActionType.OpenPaymentModal})
    }

    async function applyDiscount( discountCode ) {
        try {
            let r = await client.get(`/api/v1/discounts/lookup?code=${discountCode}`)
            dispatch({type:ActionType.SetDiscount, data: r.data})    
        } catch(e) {
            dispatch({type:ActionType.SetInvalidDiscount, data: e.response.data})
        }
    }

    async function createAuthorizationRequest() {

        try {

            let r = await client.post(`/api/v1/reservations/${state.id}/authorize`)

        } catch(e) {
            console.error(e)
        }

    }

    async function clearAuthorizationRequest() {
        try {

            let r = await client.delete(`/api/v1/reservations/${state.id}/authorize`)
            await reloadReservation()

        } catch(e) {
            console.error(e)
        }
    }

    async function vipSync() {
        if(state.id) {
            dispatch({type:ActionType.VipSyncInProgress,value:true})
            await client.post(`/api/v1/reservations/${state.id}/vip_sync`, {id:state.id})
            await reloadReservation()
            dispatch({type:ActionType.VipSyncInProgress,value:false})
        }
    }

    async function vipMarkAsPaid() {
        if(state.id) {
            dispatch({type:ActionType.VipSyncInProgress,value:true})
            await client.post(`/api/v1/reservations/${state.id}/vip_paid`, {id:state.id})
            await reloadReservation()
            dispatch({type:ActionType.VipSyncInProgress,value:false})
        }
    }

    async function save(notifyCustomer=false) {

        let payload = {
            discount: state.discount,
            customer: state.customer,
            lineItems: state.lineItems,
            notify_customer: notifyCustomer
        }

        if(state.id) {
            await client.put(`/api/v1/reservations/${state.id}`, {id:state.id,...payload})
            await reloadReservation()
        } else {
            await client.post("/api/v1/reservations", payload)
        }


    }


    useEffect(()=>{
        if(editable === true) {
            dispatch({type:ActionType.ToggleEditMode})
        }
    },[editable])

    useEffect(()=>{
        if(id) {
            loadReservation(id)
        }
    },[id])

    useEffect(()=>{
        if(checkinId) {
            loadCheckin(checkinId)
        }
    },[checkinId])
    

    useEffect(()=>{

        if(state.customer?.id) {
            loadCustomerBalance()
        }

    },[state.customer])

    return (
        <Context.Provider value={{ state,vipMarkAsPaid, vipSync, dispatch , save,updateDriver, makePayment,reloadReservation, applyDiscount, reloadCheckin, cancelTransaction,editTransaction, createAuthorizationRequest,clearAuthorizationRequest}}>
            {children}
        </Context.Provider>
    )
}

export const useReservationContext = () => useContext(Context)
