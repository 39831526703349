import classNames from 'classnames';
import { eachMonthOfInterval, eachQuarterOfInterval, eachYearOfInterval, format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import BookingsSlideOver from './BookingsSlideOver';

export function formatCurrency(money) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(money).replace(/\D00$/, '');
}

enum PivotType {
    Month = "MONTH",
    Quarter = "QUARTER",
    Year = "YEAR"
}

function getSlug(date, pivot) {

    if (pivot == PivotType.Year) {
        return format(date, "yyy")
    }

    if (pivot == PivotType.Quarter) {
        return format(date, "QQQ")
    }

    if (pivot == PivotType.Month) {
        return format(date, "yyy-MM-'01'")
    }
    return null
}


export default function TopCars({ bookings }) {

    let [data, setData] = useState([])
    let [current, setCurrent] = useState()
    let [pivot, setPivot] = useState<PivotType>(PivotType.Quarter)
    let [showBookings, setShowBookings] = useState(false)
    let [items, setItems] = useState([])
    let [dates, setDates] = useState([])

    useEffect(() => {

        if (!bookings) {
            return
        }

        let year = new Date().getFullYear()
        let intervals = eachMonthOfInterval({ start: new Date(year, 0, 1), end: new Date(year, 11, 1) })
        if (pivot == PivotType.Quarter) {
            intervals = eachQuarterOfInterval({ start: new Date(year, 0, 1), end: new Date(year, 11, 1) })
        }
        if (pivot == PivotType.Year) {
            intervals = eachYearOfInterval({ start: new Date(year, 0, 1), end: new Date(year, 11, 1) })
        }

        setDates(intervals)


        let topCars = Object.values(bookings.reduce((acc, r) => {
            acc = acc.concat(r.line_items.map(x => ({ ...x, customer: r.customer })))
            return acc
        }, []).reduce((acc, li) => {

            if (!acc[li.vehicle.id]) {
                acc[li.vehicle.id] = {
                    vehicle: li.vehicle,
                    dates: intervals.reduce((acc, d) => {
                        let slug = getSlug(d, pivot)
                        acc[slug] = {
                            date: d,
                            total: 0,
                            gross: 0,
                            bookings: [],
                        }
                        return acc
                    }, {})
                }
            }

            let date = parseISO(li.date)
            let slug = getSlug(date, pivot)

            if(!acc[li.vehicle.id] || !acc[li.vehicle.id].dates[slug]) {
                return acc
            }

            acc[li.vehicle.id].dates[slug].gross += parseFloat(li.unit_price)
            acc[li.vehicle.id].dates[slug].total += 1
            acc[li.vehicle.id].dates[slug].bookings.push(li)

            return acc
        }, {}))

        setData(topCars)


    }, [bookings, pivot])


    function toggleBookings() {
        setShowBookings(!showBookings)
    }

    function displayBookings( bookings ) {
        setShowBookings(true)
        setItems(bookings)
    }


    return (
        <>
            <div>
                <BookingsSlideOver open={showBookings} onClose={toggleBookings} bookings={items} />
            </div>        
            

            <div className="flex mb-5 items-center">
                <div className="text-xl font-bold">Top Car</div>
                <div className="ml-auto flex">

                    <div className={classNames("p-1 mr-2 text-xs text-center border border-gray-400 rounded-md cursor-pointer",{
                        "bg-gray-400 text-gray-900 hover:bg-gray-600": (pivot == PivotType.Month),
                        "hover:bg-gray-400 hover:text-gray-100": !(pivot == PivotType.Month)
                    })} onClick={(e) => setPivot(PivotType.Month)}>Month</div>

                    <div className={classNames("p-1 mr-2 text-xs text-center border border-gray-400 rounded-md cursor-pointer",{
                        "bg-gray-400 text-gray-900 hover:bg-gray-600": (pivot == PivotType.Quarter),
                        "hover:bg-gray-400 hover:text-gray-100": !(pivot == PivotType.Quarter)
                    })}  onClick={(e) => setPivot(PivotType.Quarter)}>Quarter</div>

                    <div className={classNames("p-1 text-xs text-center border border-gray-400 rounded-md cursor-pointer",{
                        "bg-gray-400 text-gray-900 hover:bg-gray-600": (pivot == PivotType.Year),
                        "hover:bg-gray-400 hover:text-gray-100": !(pivot == PivotType.Year)
                    })}  onClick={(e) => setPivot(PivotType.Year)}>Year</div>

                </div>
            </div>

            <div className={`grid grid-cols-${4 + (dates.length * 2)}`}>
                <div className="col-span-4 bg-gray-800 p-1 row-span-2 flex items-center font-bold text-sm ">Vehicle</div>
                {dates.map((q) => {

                    let label = format(q, "MMM")

                    if (pivot == PivotType.Quarter) {
                        label = format(q, "QQQQ")
                    }

                    if (pivot == PivotType.Year) {
                        label = format(q, "yyy")
                    }

                    return (
                        <div key={q} className="col-span-2 bg-gray-800 text-sm p-1">{label}</div>
                    )
                })}

                {dates.map((q) => {
                    return (
                        <React.Fragment key={q}>
                            <div className="bg-gray-800 text-xs p-1">Gross</div>
                            <div className="bg-gray-800 text-xs p-1">Booked</div>
                        </React.Fragment>
                    )
                })}

                {data.map((t) => {

                    return (
                        <React.Fragment key={t.vehicle.id}>
                            <div  className="col-span-4 text-sm border-b border-gray-800 border-r p-1">{t.vehicle.name}</div>
                            {Object.keys(t.dates).map((k) => {

                                let data = t.dates[k]

                                if (!data.gross) {
                                    return (
                                        <React.Fragment key={k}>
                                            <div className="text-gray-400 border-b border-gray-800 border-r p-1"> - </div>
                                            <div className="text-gray-400 border-b border-gray-800 border-r p-1"> - </div>
                                        </React.Fragment>
                                    )
                                }

                                return (
                                    <div className="col-span-2 grid grid-cols-2 cursor-pointer hover:bg-gray-800" onClick={()=>{
                                        displayBookings(data.bookings)
                                    }} key={k}>
                                        <div className="text-xs text-center border-b border-gray-800 border-r p-1">{formatCurrency(data.gross)}</div>
                                        <div className="text-xs text-center border-b border-gray-800  p-1 border-r">{data.total}</div>
                                    </div>
                                )
                            })}
                        </React.Fragment>
                    )
                })}

                
            </div>


        </>
    )
}
